import React from "react";
import LandingPage from "../components/LandingPage/LandingPage";
import { graphql } from "gatsby";

function Index({ data }) {
  return <LandingPage data={data} />;
}

export const query = graphql`
  {
    allContentfulLandingPage(filter: { slug: { eq: "/" } }) {
      ...LandingPageData
    }
  }
`;

export default Index;
